<template>
  <main class="mx-auto px-2 sm:px-4 py-4 md:px-0 min-h-screen">
    <div
      class="flex flex-row-reverse items-center justify-between sm:flex-col sm:items-start mb-6 sm:mb-0"
    >
      <h4
        class="md:mt-3 md:text-sm md:text-left text-xs font-medium text-dark-600 sm:text-headerText capitalize sm:uppercase"
      >
        Payroll / Payslips
      </h4>
    </div>

    <div
      v-if="
        !isSearch &&
        !(listPayrollHistory.dataByYear && listPayrollHistory.dataByYear.length)
      "
    >
      <div
        v-if="!loading"
        class="mt-16 mb-4 px-4 w-full sm:w-8/12 md:w-6/12 mx-auto"
      >
        <h2 class="text-xl md:text-2xl font-medium">
          You have not sent any payslip yet
        </h2>
        <img
          src="@/assets/img/payslip-empty.png"
          class="w-full mx-auto -mb-20 md:-mb-24"
          alt=""
        />
        <easiButton
          @click="$router.push({ name: 'PaySalary' })"
          class="w-10/12"
        >
          Run Payroll
        </easiButton>
      </div>
    </div>
    <div v-else class="w-full md:px-10">
      <div class="my-6 md:my-10 w-full mx-auto">
        <div
          class="relative flex md:flex-row flex-col md:justify-between justify-center mx-auto w-full md:gap-y-0 gap-y-8 md:items-center"
        >
          <div
            class="w-full bg-white p-4 rounded-xl shadwo-md border border-indigo-100 flex justify-between items-center mx-auto"
          >
            <span
              style="z-index: 2"
              class="md:text-2xl text-lg font-bold uppercase"
              >Payslips</span
            >
            <div class="flex items-center gap-2">
              <YearSelect v-model:year="selectedYear" />
              <easiButton
                style="z-index: 4"
                @click="showSendPayslip = true"
                class="md:py-4"
              >
                <span class=""> Send Payslip </span>
              </easiButton>
            </div>
          </div>
          <div
            class="hidden md:flex md:w-96 absolute top-2 left-1/2 transform -translate-x-2/3 z-0"
          >
            <img
              src="@/assets/icons/payslip-cash.svg"
              alt=""
              class="w-full absolute top-0 left-10 pointer-events-none"
            />
          </div>
        </div>
      </div>

      <div e class="w-full">
        <div
          v-if="
            isSearch &&
            !(
              listPayrollHistory.dataByYear &&
              listPayrollHistory.dataByYear.length
            )
          "
          class="no-printable container mx-auto items-center justify-center w-full sm:w-11/12 md:w-10/12 my-6 grid grid-cols-2 gap-2 mt-12"
        >
          <div class="flex flex-col text-left col-span-2 md:col-span-1">
            <h1 class="text-secondary text-5xl md:text-7xl font-semibold mb-4">
              Oops!
            </h1>
            <div class="text-sm md:text-lg">
              Sorry we couldn’t find a result for your request.
            </div>
            <p class="text-xs md:text-sm mt-2">Please try a different year</p>
          </div>
          <div class="col-span-2 md:col-span-1">
            <img src="@/assets/icons/search_null.svg" alt="" />
          </div>
        </div>
        <div class="w-full" v-if="visibleData.length">
          <div class="w-full my-6" v-for="(item, i) in visibleData" :key="i">
            <div
              class="hidden md:block text-left w-full text-dark-900 md:text-2xl font-bold"
            >
              Year {{ item.year }}
            </div>
            <div
              class="mb-7 md:hidden text-center border-b-4 border-newLightgrey w-full text-dark-800 text-3xl font-medium"
            >
              {{ item.year }}
            </div>
            <div class="w-full mt-2 mb-4"></div>
            <payslip-table :data="item.data" />
          </div>
        </div>
      </div>
      <!-- //////// Pagination //////// -->
      <easiPagin
        v-if="visibleData.length"
        :data="listPayrollHistory.dataByYear"
        @page:update="updatePage"
        :currentPage="currentPage"
        :pageSize="pageSize"
        class="my-4"
      />
    </div>

    <SendPayslip v-if="showSendPayslip" @close="showSendPayslip = false" />

    <easiRoundLoader v-if="loading" />
  </main>
</template>

<script setup>
import { computed, ref, onMounted, reactive, watch } from "vue";
import { useDataStore } from "@/stores/data.js";
import { useRoute, useRouter } from "vue-router";
import { useToast } from "vue-toastification";
import { storeToRefs } from "pinia";

import YearSelect from "@/components/global/YearSelect.vue";
import SendPayslip from "@/components/Payroll/Payslip/SendPayslip.vue";
import PayslipTable from "@/components/Payroll/Payslip/PayslipTable.vue";

import payrollHistory from "@/utils/payrollHistory";

const store = useDataStore();
const { query } = store;

const listPayrollHistory = computed(() => store.getPayrollHistory);
// console.log("Payroll History Payslip", listPayrollHistory.value);

const route = useRoute();
const toast = useToast();

const showSendPayslip = ref(false);
const loading = ref(false);
const isSearch = ref(false);

const currentPage = ref(0);
const pageSize = ref(2);
let visibleData = ref([]);

const payload = reactive({
  endMonth: 11,
  startMonth: 0,
  endYear: new Date().getFullYear(),
  year: new Date().getFullYear(),
});

const selectedYear = ref(new Date().getFullYear());

watch(selectedYear, () => {
  payload.endYear = selectedYear.value;
  payload.year = selectedYear.value;
  isSearch.value = true;
  queryPayroll();
});

onMounted(async () => {
  await queryPayroll();
  await updateVisibleData();
});

const updatePage = (pageNumber) => {
  currentPage.value = pageNumber;
  updateVisibleData();
};

const updateVisibleData = () => {
  // Sort Data by year
  listPayrollHistory.value.dataByYear =
    listPayrollHistory.value.dataByYear.sort(
      (a, b) => new Date(b.year) - new Date(a.year)
    );

  let datas = listPayrollHistory.value.dataByYear;
  visibleData.value = datas.slice(
    currentPage.value * pageSize.value,
    currentPage.value * pageSize.value + pageSize.value
  );
  // if we have 0 visible todos, go back a page
  if (visibleData.value.length == 0 && currentPage.value > 0) {
    updatePage(currentPage.value - 1);
  }
};

const queryPayroll = async () => {
  loading.value = true;
  try {
    const res = await query({
      endpoint: "ListPayrollHistory",
      payload: {
        input: payload,
      },
      service: "PAYROLL",
      storeKey: "listPayrollHistory",
    });

    loading.value = false;
    updateVisibleData();
    console.log("ListPayrollHistory");
  } catch (err) {
    loading.value = false;
    console.log(err);
  }
};

// Get rid of the proxy when console.log
function log(arg) {
  console.log(JSON.parse(JSON.stringify(arg)));
}
</script>

<style></style>
