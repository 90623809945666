<template>
  <main>
    <!-- ///// Desktop Frame ///// -->

    <div class="overflow-y-auto hidden md:block rounded-t-xl text-primary">
      <table class="w-full">
        <thead class="">
          <tr class="text-header font-bold shadow-md bg-dark-100 relative">
            <th
              v-for="(title, index) in headers"
              :key="index"
              class="bg-newdirtyGreen border border-outlineGray font-bold uppercase text-sm tracking-wide text-header text-center whitespace-nowrap p-0"
            >
              <div class="px-2 py-3 border">
                {{ title }}
              </div>
            </th>
          </tr>
        </thead>

        <tbody class="relative">
          <!-- <div class="absolute left-0 -top-1 w-full">
            <easiLoader />
          </div> -->
          <tr
            v-for="(item, i) in data"
            :key="i"
            class="hover:bg-newAsh group text-tableBpdy cursor-pointer relative"
            @click="viewSingleRecord(item)"
          >
            <td
              class="border border-outlineGray text-left text-sm px-2 md:pl-8 py-3 bg-highlight group-hover:bg-outlineGray group-hover:border-outlineGray capitalize font-medium"
            >
              {{ months[item.month] }}
            </td>
            <td
              class="border border-outlineGray text-sm px-2 py-3 bg-white group-hover:bg-newAsh font-medium text-center"
            >
              {{ formatAmount(item.totalGross) }}
            </td>
            <td
              class="border border-outlineGray text-sm px-2 py-3 bg-white group-hover:bg-newAsh font-medium text-center"
            >
              {{ formatAmount(item.totalPaid) }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- ///// Mobile Frame ///// -->
    <div v-for="(item, i) in data" :key="i" class="w-full mb-8 block md:hidden">
      <table class="table-auto w-full overflow-y-auto">
        <tbody class="font-medium text-sm">
          <tr @click="viewSingleRecord(item)" class="cursor-pointer">
            <td
              class="w-6/12 border border-primary text-left p-2 px-3 bg-white uppercase"
            >
              <div class="flex items-center gap-2">Month</div>
            </td>
            <td
              class="w-6/12 border bg-newLimeGreen border-outlineGray text-left p-2 px-3"
            >
              <div class="flex items-center gap-2">
                {{ months[item.month] }}
              </div>
            </td>
          </tr>
          <tr @click="viewSingleRecord(item)" class="cursor-pointer">
            <td
              class="w-6/12 border border-primary text-left p-2 px-3 bg-white uppercase"
            >
              <div class="flex items-center gap-2">Total Taxable Payroll</div>
            </td>
            <td
              class="w-6/12 border border-outlineGray text-left p-2 px-3 bg-white"
            >
              <div class="flex items-center gap-2">
                {{ formatAmount(item.totalGross) }}
              </div>
            </td>
          </tr>
          <tr @click="viewSingleRecord(item)" class="cursor-pointer">
            <td
              class="w-6/12 border border-primary text-left p-2 px-3 bg-white uppercase"
            >
              <div class="flex items-center gap-2">Total Payroll</div>
            </td>
            <td
              class="w-6/12 border border-outlineGray text-left p-2 px-3 bg-white"
            >
              <div class="flex items-center gap-2">
                {{ formatAmount(item.totalPaid) }}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </main>
</template>

<script setup>
import { ref, reactive, onMounted } from "vue";
import { useDataStore } from "@/stores/data.js";
import { useRoute, useRouter } from "vue-router";
import { storeToRefs } from "pinia";
import { helperFunctions } from "@/composable/helperFunctions";

const route = useRoute();
const router = useRouter();
const store = useDataStore();
const { query, set } = store;

const { payrollForMonth } = storeToRefs(store);
const { calculatePercentage, formatAmount } = helperFunctions;

const props = defineProps({
  data: {
    type: Array,
    default: () => ({}),
  },
});

console.log("Logggginggg data");
console.log(props.data);

const headers = reactive(["Month", "Total Taxable Payroll", "Total Payroll"]);

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const viewSingleRecord = (payload) => {
  console.log("view single");
  console.log(payload);
  const biweekly = payload.timeline == "BIWEEKLY" ? `,${payload.biweekly}` : "";

  router.push({
    name: "PayslipDetails",
    params: {
      date: `${payload.month},${payload.year},${payload.timeline}${biweekly}`,
    },
  });
};

// Get rid of the proxy when console.log
function log(arg) {
  console.log(JSON.parse(JSON.stringify(arg)));
}
</script>

<style></style>
